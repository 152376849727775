<template>
  <div class="content-index" id="index">
    <div class="mobile-circle"><img src="../assets/images/mobile_circle.png"/></div>
    <div class="content-inner">
      <div class="content-txt">
        <div class="l-tit">好未来第一届</div>
        <div class="b-tit">PHP开源技术大会</div>
        <div class="l-tit-img"><img src="../assets/images/title_1.png"/></div>
        <div class="b-tit-img"><img src="../assets/images/title_2.png"/></div>
        <div class="detail">「好未来第一届PHP开源技术大会」是智慧教育国家新一代人工智能开放创新平台与好未来集团联合召开的一场针对PHP从业者及爱好者的一场技术分享交流大会。<br/>大会旨在通过“开源、分享、共建”的模式，推动开源文化氛围的形成与技术组织变革，促进整个教育生态技术共享，提升教育科技实力，更好的为“科技与爱让教育更美好”的愿景奠定基础。</div>
        <div class="concat">
          <h6>大会时间：2020年12月5日（周六）</h6>
          <p>会议地点：北京市-黄河京都会议中心-3号楼</p>
          <p>交通方式：乘坐地铁5号线至天通苑南站下车，下车后步行1.2公里</p>
          <!-- <h5>大会联系人：丛先生1234556789</h5> -->
        </div>
        <div class="enter-btn"><a href="https://lecture.xueersi.com/pc/phplive/?planId=2467603" target="_blank">大会直播入口</a></div>
        <div class="enter-ts">* 需输入购票手机号验证通过方可观看直播</div>
      </div>
      <div class="down-btn"></div>
      <!--动态光效-->
      <!-- <div class="index-banner"><img src="../assets/images/index_banner.png"/></div> -->
      <div class="light-left light-move"><img src="../assets/images/light_left.png"/></div>
      <!-- <div class="light-right light-move"><img src="../assets/images/light_right.png"/></div> -->
      <div class="light-top light-move"><img src="../assets/images/light_top.png"/></div>
      <div class="light-bottom light-move"><img src="../assets/images/light_bottom.png"/></div>
      <div class="circle-b light-move"><img src="../assets/images/circle_b.png"/></div>
      <div class="circle-l light-move"><img src="../assets/images/circle_l.png"/></div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data () {
    return {}
  },
  mounted () {},
  methods: {}
}
</script>
<style lang='scss' scoped>
/* 第一页内容 */
.content-index {
  position: relative;
  .mobile-circle {
    display: none;
  }
  .content-inner {
    width: 1200px;
    height: calc(100vh - 79px);
    min-height: 600px;
    margin: 0 auto;
    position: relative;
    .content-txt {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      color: #ffffff;
      text-align: left;
      width: 616px;
      text-align: justify;
      .l-tit {
        font-size: 55px;
        line-height: 55px;
        margin-bottom: 16px;
      }
      .b-tit {
        font-size: 74px;
        font-weight: bold;
        line-height: 74px;
        margin-bottom: 36px;
      }
      .l-tit-img {
        display: none;
      }
      .b-tit-img {
        display: none;
      }
      .detail {
        font-size: 16px;
        line-height: 28px;
        padding-right: 16px;
      }
      .concat {
        margin-top: 40px;
        h6 {
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 15px;
          color: #FF9A1A;
        }
        p {
          font-size: 16px;
          line-height: 25px;
        }
        h5 {
          font-size: 16px;
          line-height: 16px;
          margin-top: 15px;
        }
      }
      .enter-btn {
        width: 224px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        user-select: none;
        cursor: pointer;
        border-radius:23px;
        border: 1px solid #FF9A1A;
        color: #FF9A1A;
        margin-top: 67px;
        font-size: 18px;
        a {
          color: #FF9A1A;
          display: block;
        }
        &:hover {
          background: #FF9A1A;
          color: #ffffff;
          a {
            color: #ffffff;
          }
        }
      }
      .enter-ts {
        font-size: 12px;
        color:#FEFEFE;
        line-height: 12px;
        margin-top: 20px;
      }
    }
    .down-btn {
      background: url(../assets/images/down_icon.png) no-repeat center center;
      background-size: 100% 100%;
      width: 47px;
      height: 27px;
      position: absolute;
      left: 50%;
      bottom: 69px;
      transform: translateX(-50%);
      animation: downMove 0.5s .13s infinite alternate;
    }
    .index-banner {
      display: none;
      position: absolute;
      right: -60px;
      top: 40px;
      height: calc(100vh - 200px);
      animation: moveFloat 2.33s .53s infinite alternate;
      img {
        height: 100%;
      }
    }
    .light-move {
      position: absolute;
      animation: moveFloat 2s .53s infinite alternate;
      img {
        height: 100%;
      }
    }
    .light-left {
      left: -234px;
      top: 132px;
      height: 268px;
    }
    .light-right {
      right: -274px;
      top: 173px;
      height: 274px;
    }
    .light-top {
      right: 340px;
      top: 0px;
      height: 120px;
      opacity: 0.4;
      animation: moveFloat 1s .53s infinite alternate;
    }
    .light-bottom {
      right: 184px;
      bottom: -93px;
      height: 160px;
      opacity: 0.6;
      animation: moveFloat 1s .53s infinite alternate;
    }
    .circle-b {
      right: -50px;
      top: 105px;
      height: 50px; 
      animation: moveFloat 2.5s .73s infinite alternate;
    }
    .circle-l {
      right: 395px;
      bottom: 100px;
      height: 47px; 
      animation: moveFloat 2.5s .33s infinite alternate;
    }
  }
}

@media screen and (max-width: 1400px) {
  .content-index {
    .mobile-circle {
      display: block;
      font-size: 0;
      position: absolute;
      right: 8px;
      top: 352px;
      opacity: 0.7;
      img {
        width: 126px;
      }
    }
    .content-inner {
      .content-txt {
        .l-tit {
          display: none;
          font-size: 45px;
          line-height: 45px;
          margin-bottom: 12px;
        }
        .b-tit {
          display: none;
          font-size: 64px;
          line-height: 64px;
          margin-bottom: 30px;
        }
        .l-tit-img {
          display: block;
          font-size: 0;
          margin-bottom: 12px;
          img {
            height: 22px;
          }
        }
        .b-tit-img {
          display: block;
          font-size: 0;
          margin-bottom: 30px;
          img {
            height: 29px;
          }
        }
        .detail {
          font-size: 14px;
          line-height: 26px;
          text-align: justify;
          padding-right: 0;
        }
        .concat {
          margin-top: 20px;
          h6 {
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 10px;
          }
          p {
            font-size: 14px;
            line-height: 20px;
          }
          h5 {
            font-size: 14px;
            line-height: 14px;
            margin-top: 10px;
          }
        }
        .enter-btn {
          width: 189px;
          height: 46px;
          line-height: 46px;
          text-align: center;
          user-select: none;
          cursor: pointer;
          border-radius:23px;
          border: 1px solid #FF9A1A;
          color: #FF9A1A;
          margin-top: 35px;
          font-size: 18px;
          a {
            color: #FF9A1A;
          }
          &:hover {
            background: #FF9A1A;
            color: #ffffff;
            a {
              color: #ffffff;
            }
          }
        }
        .enter-ts {
          font-size: 12px;
          color:#FEFEFE;
          line-height: 12px;
          margin-top: 20px;
        }
      }
      .down-btn {
        background: url(../assets/images/down_icon.png) no-repeat center center;
        background-size: 100% 100%;
        width: 47px;
        height: 27px;
        position: absolute;
        left: 50%;
        bottom: 69px;
        transform: translateX(-50%);
        animation: downMove 0.5s .13s infinite alternate;
      }
      .index-banner {
        display: block;
        position: absolute;
        right: -60px;
        top: 40px;
        height: calc(100vh - 200px);
        animation: moveFloat 2.33s .53s infinite alternate;
        img {
          height: 100%;
        }
      }
      .light-move {
        position: absolute;
        animation: moveFloat 2s .53s infinite alternate;
        img {
          height: 100%;
        }
      }
      .light-left {
        left: -234px;
        top: 132px;
        height: 268px;
      }
      .light-right {
        right: -274px;
        top: 173px;
        height: 274px;
      }
      .light-top {
        right: 340px;
        top: 32px;
        height: 123px;
        animation: moveFloat 1s .53s infinite alternate;
      }
      .light-bottom {
        right: 173px;
        bottom: 0px;
        height: 203px;
        opacity: 0.44;
        animation: moveFloat 2s .53s infinite alternate;
      }
      .circle-b {
        right: -50px;
        top: 105px;
        height: 50px; 
        animation: moveFloat 2.5s .73s infinite alternate;
      }
      .circle-l {
        right: 395px;
        bottom: 100px;
        height: 30px; 
        animation: moveFloat 2.5s .33s infinite alternate;
      }
    }
  }
}

@media screen and (max-width: 988px) {
  .content-index {
    .content-inner {
      width: 100%;
      height: auto;
      min-height: auto;
      .content-txt {
        width: 100%;
        padding: 33px 14px 33px 15px;
        box-sizing: border-box;
        position: relative;
        transform: none;
        .l-tit {
          font-size: 22px;
          line-height: 22px;
          margin-bottom: 18px;
        }
        .b-tit {
          font-size: 29px;
          line-height: 29px;
          margin-bottom: 34px;
        }
        .detail {
          font-size: 13px;
          line-height: 18px;
          opacity: 0.8;
        }
        .concat {
          margin-top: 22px;
          h6 {
            font-size: 13px;
            line-height: 13px;
            margin-bottom: 5px;
            opacity: 0.8;
          }
          p {
            font-size: 13px;
            line-height: 18px;
            opacity: 0.8;
          }
          h5 {
            font-size: 12px;
            line-height: 12px;
            margin-top: 5px;
            opacity: 0.8;
          }
        }
        .enter-btn {
          width: 189px;
          height: 34px;
          line-height: 34px;
          border-radius:17px;
          font-size: 13px;
          margin-top: 32px;
        }
        .enter-ts {
          font-size: 10px;
          color:#FEFEFE;
          line-height: 12px;
          margin-top: 6px;
          opacity: 0.6;
        }
      }
      .down-btn {
        display: none;
        width: 28px;
        height: 14px;
        position: absolute;
        left: 50%;
        bottom: 39px;
        margin-left: -14px;
        animation: downMove 0.5s .13s infinite alternate;
      }
      .index-banner {
        position: absolute;
        right: 0px;
        top: 0px;
        height: auto;
        width: 70%;
        animation: none;
        z-index: -1;
        img {
          width: 100%;
        }
      }
      .light-move {
        display: none;
      }
      .light-left {
        display: none;
      }
      .light-right {
        display: none;
      }
      .light-top {
        display: none;
      }
      .light-bottom {
        display: none;
      }
      .circle-b {
        display: none;
      }
      .circle-l {
        display: none;
      }
    }
  }
}

/* 动画 */
@keyframes moveFloat {
  0% {
    -webkit-transform: translate(-10px);
    transform: translate(-10px)
  }
  to {
    -webkit-transform: translateY(10px);
    transform: translateY(10px)
  }
}
@keyframes downMove {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px)
  }
  to {
    -webkit-transform: translateY(10px);
    transform: translateY(10px)
  }
}
</style>
