<template>
  <div class="footer">
    <div class="footer-company"><span>主办方：</span><img class="b_logo_1" src="../assets/images/company_4.png"/><img class="b_logo_2" src="../assets/images/company_2.png"/><img class="b_logo_3" src="../assets/images/new_logo.png"/></div><div class="footer-company"><span>大会战略合作社区：</span><img src="../assets/images/company_3.png"/></div><div class="footer-company">组委会联系方式：promote@tal.com</div>
  </div>
</template>
<script>
export default {
  name: '',
  data () {
    return {}
  },
  mounted () {},
  methods: {}
}
</script>
<style lang='scss' scoped>
.footer {
  height: 112px;
  line-height: 112px;
  width: 100%;
  background: #0F054B;
  text-align: center;
  font-size: 16px;
  color: rgba(255,255,255,0.8);
  .footer-company {
    display: inline-block;
    vertical-align: middle;
    margin: 0 40px;
    span {
      vertical-align: middle;
    }
    img {
      height: 27px;
      vertical-align: middle;
    }
    .b_logo_1 {
      height: 30px;
      margin-right: 15px;
    }
    .b_logo_3 {
      height: 21px;
      margin-left: 15px;
    }
    &:nth-child(2) {
      img {
        height: 24px;
      }
    }
  }
}
@media screen and (max-width: 988px) {
  .footer {
    height: auto;
    line-height: 25px;
    font-size: 12px;
    padding: 20px 0;
    box-sizing: border-box;
    .footer-company {
      margin: 0 20px;
      img {
        height: 20px;
        vertical-align: middle;
      }
      .b_logo_1 {
        height: 20px;
        margin-right: 16px;
      }
      .b_logo_3 {
        height: 14px;
        margin-left: 16px;
      }
      &:nth-child(2) {
        img {
          height: 14px;
        }
      }
    }
  }
}
</style>
