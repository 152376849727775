<template>
  <div class="indexPerson" id="person">
    <!-- <div class="content_right"><img src="../assets/images/inner_right.png"/></div> -->
    <div class="person-tit">大会嘉宾<span>(排名不分先后)</span></div>
    <div class="person-list">
      <ul class="clearfix">
        <li class="list-item">
          <div class="item-img"><img src="../assets/images/tm_img.jpg"/></div>
          <div class="item-info">
            <h3>田密</h3>
            <p>好未来集团CTO</p>
          </div>
        </li>
        <li class="list-item" @click="personHover(1)">
          <div class="item-img"><img src="../assets/images/htf_img.jpg"/></div>
          <div class="item-info">
            <h3>韩天峰</h3>
            <p>好未来学而思网校首席架构师</p>
          </div>
          <div class="item-txt" :class="{changeOn:currentIndex == 1}">
            <h3>韩天峰</h3>
            <p>韩天峰，好未来学而思网校首席架构师，Swoole开源项目创始人，曾在腾讯朋友网、淘宝搜索任系统架构师，虎牙直播移动端后台负责人、 车轮互联总架构师。</p>
          </div>
        </li>
        <li class="list-item" @click="personHover(2)">
          <div class="item-img"><img src="../assets/images/nikita_img.jpg"/></div>
          <div class="item-info">
            <h3>Nikita</h3>
            <p>PHP内核核心开发者</p>
          </div>
          <div class="item-txt" :class="{changeOn:currentIndex == 2}">
            <h3>Nikita</h3>
            <p>Nikita Popov，长期的PHP内核贡献者, 目前就职于JetBrains。实现了Generator、强类型属性、箭头函数和很多其他PHP特性。同时也是PHP-Parser库的维护者和LLVM工具链的贡献者。</p>
          </div>
        </li>
        <li class="list-item" @click="personHover(3)">
          <div class="item-img"><img src="../assets/images/ccqh_img.jpg"/></div>
          <div class="item-info">
            <h3>陈曹奇昊</h3>
            <p>PHP & Swoole 内核开发者</p>
          </div>
          <div class="item-txt" :class="{changeOn:currentIndex == 3}">
            <h3>陈曹奇昊</h3>
            <p>陈曹奇昊，网名 Twosee，Swoole核心开发者，（PHPyach内核贡献者上），长期活跃于PHP或异步网络IO相关的各大开源项目社区。</p>
          </div>
        </li>
        <li class="list-item" @click="personHover(4)">
          <div class="item-img"><img src="../assets/images/hch_img.jpg"/></div>
          <div class="item-info">
            <h3>黄朝晖</h3>
            <p>KK集团技术负责人<br/>Hyperf创始人</p>
          </div>
          <div class="item-txt" :class="{changeOn:currentIndex == 4}">
            <h3>黄朝晖</h3>
            <p>黄朝晖，目前就职于 KK集团，产品技术负责人，阿里云 MVP，开源项目 Hyperf 的创始人，目前致力于推动 PHP 微服务生态的发展。</p>
          </div>
        </li>
        <li class="list-item" @click="personHover(5)">
          <div class="item-img"><img src="../assets/images/czz_img.jpg"/></div>
          <div class="item-info">
            <h3>陈宗志</h3>
            <p>阿里巴巴-数据库产品事业部<br/>高级技术专家</p>
          </div>
          <div class="item-txt" :class="{changeOn:currentIndex == 5}">
            <h3>陈宗志</h3>
            <p>陈宗志（暴跳），阿里云数据库产品事业部高级技术专家，对数据库, 分布式存储有一定了解，著名开源项目大容量redis Pika 的作者。</p>
          </div>
        </li>
        <li class="list-item" @click="personHover(6)">
          <div class="item-img"><img src="../assets/images/wbx_img.jpg"/></div>
          <div class="item-info">
            <h3>吴炳锡</h3>
            <p>知数堂联合创始人</p>
          </div>
          <div class="item-txt" :class="{changeOn:currentIndex == 6}">
            <h3>吴炳锡</h3>
            <p>吴炳锡，知数堂联合创始人，3306π社区联合创始人，腾讯TVP成员，15年MySQL从业老兵。担任多年DTCC，SACC， Oracle技术嘉年华讲师及顾问； 擅长多年MySQL系统架构设计及培训教学经验&MySQL大规模运维管理优化、高可用方案、多IDC架构设计，企业级应用数据库设计等。</p>
          </div>
        </li>
        <li class="list-item" @click="personHover(7)">
          <div class="item-img"><img src="../assets/images/hdx_img.jpg"/></div>
          <div class="item-info">
            <h3>黄东旭</h3>
            <p>PingCAP 联合创始人 CTO</p>
          </div>
          <div class="item-txt" :class="{changeOn:currentIndex == 7}">
            <h3>黄东旭</h3>
            <p>黄东旭，PingCAP 联合创始人兼 CTO，资深基础软件工程师，架构师，曾就职于微软亚洲研究院，网易有道及豌豆荚，擅长分布式系统以及数据库开发，在分布式存储领域有丰富的经验和独到的见解。狂热的开源爱好者以及开源软件作者，代表作品分布式 Redis 缓存方案 Codis，以及分布式关系型数据库 TiDB。</p>
          </div>
        </li>
        <li class="list-item" @click="personHover(8)">
          <div class="item-img"><img src="../assets/images/gct_img.jpg"/></div>
          <div class="item-info">
            <h3>高驰涛</h3>
            <p>云智慧技术副总裁</p>
          </div>
          <div class="item-txt" :class="{changeOn:currentIndex == 8}">
            <h3>高驰涛</h3>
            <p>高驰涛（Neeke Gao），云智慧技术副总裁，PHP/PECL开发组成员，SCG（SeasX Core Group）Leader。10年+研发管理经验，早期从事大规模企业信息化架构研发，09年涉足互联网数字营销领域并深入研究架构与性能优化。2014年始致力于 APM 产品和AIOPS产品的架构研发。崇尚敏捷，高效，GettingReal。</p>
          </div>
        </li>
        <li class="list-item" @click="personHover(9)">
          <div class="item-img"><img src="../assets/images/ccf_img.jpg"/></div>
          <div class="item-info">
            <h3>陈朝飞</h3>
            <p>好未来后端资深专家</p>
          </div>
          <div class="item-txt" :class="{changeOn:currentIndex == 9}">
            <h3>陈朝飞</h3>
            <p>陈朝飞，有多年服务端开发和高峰应对经验，目前就职于好未来学而思网校基础架构部，云计算负责人。</p>
          </div>
        </li>
        <li class="list-item" @click="personHover(10)">
          <div class="item-img"><img src="../assets/images/qp_img.jpg"/></div>
          <div class="item-info">
            <h3>秦朋</h3>
            <p>腾讯高级工程师<br/>《PHP 7内核剖析》作者</p>
          </div>
          <div class="item-txt" :class="{changeOn:currentIndex == 10}">
            <h3>秦朋</h3>
            <p>秦朋，腾讯高级开发工程师，《PHP7内核剖析》作者。曾就职于360、搜狗、滴滴等公司，有6年后端开发经验、擅长PHP/Go/C/C++、熟悉PHP底层的实现原理，目前就职于腾讯，从事联盟广告相关的工作。</p>
          </div>
        </li>
        <li class="list-item" @click="personHover(11)">
          <div class="item-img"><img src="../assets/images/xhl_img.jpg"/></div>
          <div class="item-info">
            <h3>谢华亮</h3>
            <p>好未来服务端研究员</p>
          </div>
          <div class="item-txt" :class="{changeOn:currentIndex == 11}">
            <h3>谢华亮</h3>
            <p>谢华亮，网名“黑夜路人” , CSDN知名PHP技术博主,曾就职于BAT等多家互联网企业,技术擅长领域在LNMP技术栈/高并发系统/分布式系统等技术方向，热衷于开源技术，个人开源了go组件/php框架/轻量级kvdb/cache等开源软件，并且构建了多个技术社群,有力推动了国内PHP和开源技术的普及推广。</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data () {
    return {
      currentIndex: 0
    }
  },
  mounted () {},
  methods: {
    personHover (index) {
      if (!this.isMobile()) return
      if (this.currentIndex == index) {
        this.currentIndex = 0
      } else {
        this.currentIndex = index
      }
    },
    isMobile () {
      if(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
				return true
			}else {
				return false
			}
    }
  }
}
</script>
<style lang='scss' scoped>
.indexPerson {
  width: 1198px;
  margin: 0 auto;
  color: #ffffff;
  padding-bottom: 26px;
  position: relative;
  .content_right {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 338px;
    transform: translate(409px, -200px);
    img {
      width: 100%;
    }
  }
  .person-tit {
    font-size: 42px;
    line-height: 42px;
    padding: 105px 0 60px 0;
    span {
      font-size: 24px;
      line-height: 24px;
      padding-left: 21px;
    }
  }
  .person-list {
    .list-item {
      width: 281px;
      height: 363px;
      background: #0D3EAB;
      box-shadow: 5px 9px 25px 0px rgba(15, 5, 75, 0.06);
      border-radius: 7px;
      float: left;
      margin-right: 24px;
      margin-bottom: 30px;
      overflow: hidden;
      position: relative;
      cursor: default;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .item-img {
        height: 237px;
        width: 100%;
        text-align: center;
        background: #999999;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        overflow: hidden;
        img {
          height: 100%;
        }
      }
      .item-info {
        padding: 28px 10px 0 10px;
        text-align: center;
        h3 {
          font-size: 24px;
          line-height: 24px;
          padding-bottom: 10px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          opacity: 0.7;
        }
      }
      .more-top {
        padding: 15px 10px 0 10px;
      }
      .item-txt {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        background: rgba(16, 0, 111, 0.95);
        padding: 38px 22px;
        box-sizing: border-box;
        overflow: hidden;
        transition: 0.6s all;
        h3 {
          text-align: center;
          font-size: 24px;
          line-height: 24px;
          padding-bottom: 16px;
        }
        p {
          font-size: 15px;
          line-height: 24px;
          overflow: hidden;
          text-overflow: -o-ellipsis-lastline;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 10;
          line-clamp: 10;
          -webkit-box-orient: vertical;
        }
      }
      &:hover {
        .item-txt {
          opacity: 1;
        }
      }
    }
  }
}
.changeOn {
  opacity: 1!important;
}
@media screen and (max-width: 988px) {
  .indexPerson {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px 20px 15px;
    box-sizing: border-box;
    .content_right {
      display: none;
    }
    .person-tit {
      font-size: 22px;
      line-height: 22px;
      padding: 50px 0 22px 0;
      span {
        font-size: 14px;
        line-height: 14px;
        padding-left: 11px;
      }
    }
    .person-list {
      .list-item {
        width: 49%;
        height: auto;
        border-radius: 8px;
        margin-right: 2%;
        margin-bottom: 8px;
        overflow: hidden;
        &:nth-child(2n) {
          margin-right: 0;
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
        .item-img {
          height: auto;
          background: none;
          img {
            width: 100%;
            height: auto;
          }
        }
        .item-info {
          padding: 10px 0;
          height: 55px;
          h3 {
            font-size: 14px;
            line-height: 14px;
            padding-bottom: 6px;
          }
          p {
            font-size: 12px;
            line-height: 16px;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
          }
        }
        .item-txt {
          padding: 13px 10px;
          transition: 0s all;
          h3 {
            font-size: 14px;
            line-height: 14px;
            padding-bottom: 6px;
          }
          p {
            font-size: 12px;
            line-height: 16px;
            -webkit-line-clamp: 11;
            line-clamp: 11;
          }
        }
        // &:hover {
        //   .item-txt {
        //     opacity: 1;
        //   }
        // }
      }
    }
  }
}

@keyframes light{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

</style>
