<template>
  <div class="tcMain">
    <div class="header">
      <div class="header-inner">
        <a class="header-logo" href="javascript:void(0);"><img src="./assets/images/new_logo.png"/></a>
        <!-- <div class="menu" @click="showMenu">
          <ul><li></li><li></li><li></li></ul>
        </div> -->
        <div class="buy-btn"><a href="https://www.bagevent.com/widget/ticket/6924626" target="_blank">购买门票</a></div>
        <ul class="nav">
          <li :class="index == currentIndex ? 'active' : ''" v-for="(item, index) in navList" :key="index" @click="changeNav(index)"><a href="javascript:void(0);">{{item}}</a></li>
        </ul>
        <!-- <div :class="isShowMenu ? 'menu-square showMenu' : 'menu-square'">
          <div class="mask" @click="hideMenu"></div>
          <ul class="mobile-nav">
            <li :class="index == currentIndex ? 'active' : ''" v-for="(item, index) in navList" :key="index" @click="changeNav(index)"><a href="javascript:void(0);">{{item}}</a></li>
          </ul>
        </div> -->
      </div>
    </div>
    <!--首页-->
    <homePage></homePage>
    <!--大会议程-->
    <indexProcess ref="IndexProcess"></indexProcess>
    <!--大会嘉宾-->
    <indexPerson></indexPerson>
    <!--底部-->
    <ifooter></ifooter>
  </div>
</template>
<script>
import homePage from './components/homePage'
import indexProcess from './components/indexProcess'
import indexPerson from './components/indexPerson'
import ifooter from './components/footer'
export default {
  name: '',
  data () {
    return {
      isShowMenu: false,
      active: 'active',
      currentIndex: 0,
      processTop: 500,
      personTop: 1600,
      navList: ['首页', '大会议程', '大会嘉宾']
    }
  },
  components: {
    homePage,
    indexProcess,
    indexPerson,
    ifooter
  },
  mounted () {
    this.processTop = document.getElementById('process').offsetTop - 80 || 500
    this.personTop = document.getElementById('person').offsetTop - 20 || 1600
    this.init()
  },
  methods: {
    init () {
      let scrolltop = null;
      let _this = this
      window.onscroll=function(){
        scrolltop = (document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop) + 10
        if (scrolltop > _this.processTop && scrolltop < _this.personTop) {
          _this.currentIndex = 1
        } else if (scrolltop >= _this.personTop) {
          _this.currentIndex = 2
        } else {
          _this.currentIndex = 0
        }
      }
    },
    changeNav (index) {
      // this.currentIndex = index
      let scrollTop = 0
      if (index == 0) {
        scrollTop == 0
      } else if (index == 1) {
        scrollTop = this.processTop
      } else {
        scrollTop = this.personTop
      }
      window.scrollTo({ 
        top: scrollTop, 
        behavior: "smooth"
      })
    },
    showMenu () {
      this.isShowMenu = true
    },
    hideMenu () {
      this.isShowMenu = false
    }
  }
}
</script>
<style lang='scss' scoped>
.tcMain {
  background-image: url('./assets/images/home_bg.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 1920px 982px;
}
.header {
  width: 100%;
  height: 79px;
  background: #1b1254;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 20;
  .header-inner {
    width: 1200px;
    margin: 0 auto;
    .header-logo {
      height: 54px;
      font-size: 0;
      float: left;
      margin-top: 12px;
      img {
        height: 100%;
      }
    }
    .menu {
      display: none;
    }
    .menu-square {
      display: none;
    }
    .nav {
      float: right;
      margin-top: 31px;
      li {
        display: inline-block;
        margin: 0 26px;
        font-size: 16px;
        position: relative;
        a {
          color: #ffffff;
        }
        &:hover {
          a {
            color: #FF9A1A;
          }
         
        }
      }
      .active {
        a {
          color: #FF9A1A;
          font-weight: 500;
        }
        &:after {
          content: '';
          width: 18px;
          height: 3px;
          background: #FF9A1A;
          display: block;
          position: absolute;
          left: 50%;
          bottom: -12px;
          transform: translateX(-50%);
        }
      }
    }
    .buy-btn {
      float: right;
      width: 115px;
      height: 36px;
      line-height: 36px;
      background: #FF9A1A;
      margin-left: 25px;
      color: #ffffff;
      border-radius: 18px;
      font-size: 18px;
      margin-top: 21px;
      user-select: none;
      text-align: center;
      cursor: pointer;
      a {
        color: #ffffff;
        display: block;
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }
}

@media screen and (max-width: 988px) {
  .tcMain {
    background-image: url('./assets/images/home_mobile_bg.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
  }
  .header {
    height: 45px;
    line-height: 45px;
    background: #1B1254;
    .header-inner {
      width: 100%;
      .header-logo {
        width: auto;
        height: 22px;
        margin-top: 11.5px;
        margin-left: 14px;
        img {
          width: auto;
          height: 100%;
        }
      }
      .menu {
        display: block;
        float: right;
        width: 25px;
        height: 32px;
        margin-right: 10px;
        margin-left: 20px;
        margin-top: 8px;
        li {
          width: 100%;
          height: 3px;
          background: #ffffff;
          opacity: 0.8;
          margin-top: 6px;
        }
      }
      .nav {
        float: inherit;
        position: absolute;
        right: 0;
        top: 0;
        background: #dedede;
        margin-top: 31px;
        li {
          display: inline-block;
          margin: 0 26px;
          font-size: 16px;
          position: relative;
          a {
            color: #ffffff;
          }
          &:hover {
            a {
              color: #FF9A1A;
            }
          
          }
        }
        .active {
          a {
            color: #FF9A1A;
            font-weight: 500;
          }
          &:after {
            content: '';
            width: 18px;
            height: 3px;
            background: #FF9A1A;
            display: block;
            position: absolute;
            left: 50%;
            bottom: -12px;
            transform: translateX(-50%);
          }
        }
      }
      .nav {
        display: none;
      }
      .showMenu {
        display: block;
      }
      .menu-square {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .mask {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.2);
          z-index: 40;
        }
      }
      .mobile-nav {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
        background: #00002e;
        height: 100%;
        padding-top: 20px;
        z-index: 50;
        li {
          margin: 30px 16px;
          font-size: 16px;
          position: relative;
          text-align: center;
          a {
            color: #ffffff;
          }
          &:hover {
            a {
              color: #FF9A1A;
            }
          }
        }
        .active {
          a {
            color: #FF9A1A;
            font-weight: 500;
          }
        }
      }
      .buy-btn {
        float: right;
        width: auto;
        height: 14px;
        line-height: 14px;
        margin-left: 0;
        border-radius: 18px;
        font-size: 14px;
        margin-top: 15.5px;
        margin-right: 14px;
        padding-right: 10px;
        border: none;
        background: none;
        cursor: pointer;
        background: url(./assets/images/enter_btn.png) no-repeat right center;
        background-size: 5px 11px;
        a {
          color: #FF9A1A;
        }
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}
</style>
<style>
body {
  background-color: #002e92;
  background-image: url('./assets/images/home_bg_repeat.jpg');
  background-repeat: repeat-x;
  background-size: auto 982px;
  overflow-x: hidden;
  /* background: linear-gradient(0deg, #003CAF, #0F054B); */
}
@media screen and (max-width: 988px) {
  body {
    background: #002D91;
  }
}
</style>
