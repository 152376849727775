<template>
  <div class="content-process" id="process">
    <div class="process-tit">大会议程<span>(12月5日 圣海厅)</span></div>
    <div class="process-content clearfix">
      <!-- <div class="content_left"><img src="../assets/images/inner_left.png"/></div> -->
      <div class="content-list left-list">
        <div class="list-tit">上半场</div>
        <div class="list-down">
          <ul>
            <li>
              <i>08:00-09:00</i>
              <h3>签到处签到</h3>
            </li>
            <li>
              <i>09:00-09:20</i>
              <h3>大会寄语</h3>
              <p>田密  好未来集团CTO</p>
            </li>
            <li>
              <i>09:20-09:40</i>
              <h3>大咖面对面</h3>
              <p>韩天峰  好未来学而思网校首席架构师</p>
            </li>
            <li>
              <i>09:40-10:20</i>
              <h3>PHP 8.0中有什么新特性？<div class="tips">PHP8.0中引入了很多新特性，即时编译器（JIT）、注解、联合类型、命名参数都只是其中较为关键的一小部分。作为一个主要版本，它还包括了一些致力于严格化错误处理机制和增强类型安全性的向后不兼容变更。演讲概述了PHP 8中的重要变化以及它们会对你产生怎样的影响。</div></h3>
              <p>Nikita PHP内核核心开发者</p>
            </li>
            <li>
              <i>10:20-11:00</i>
              <h3>PHP 8与下一代协程技术演进<div class="tips">介绍过去一年内Swoole社区的研究成果和探讨即将到来的新版本Swoole能否帮助PHP在高性能网络编程领域占有一席之地。分享对开源的一些见解，以及PHP程序员如何拥抱变化，突破困局。</div></h3>
              <p>陈曹奇昊 PHP & Swoole内核开发者</p>
            </li>
            <li>
              <i>11:00-11:40</i>
              <h3>Hyperf 设计之魂<div class="tips">Hyperf 是怎么出来的，为什么要做现在的这些 feature，为什么要这么做，做这个框架的核心思想和逻辑是什么。</div></h3>
              <p>黄朝晖 KK集团技术负责人、Hyperf创始人</p>
            </li>
            <li>
              <i>11:40-13:30</i>
              <h3>统一就餐区就餐</h3>
            </li>
          </ul>
        </div>
      </div>
      <div class="content-list right-list">
        <div class="list-tit">下半场</div>
        <div class="list-down">
          <ul>
            <li>
              <i>13:30-13:40</i>
              <h3>抽奖互动</h3>
            </li>
            <li>
              <i>13:40-14:20</i>
              <h3>PolarDB 云原生数据库内核揭秘<div class="tips">介绍阿里云云原生数据库PolarDB 内核实现。包括PolarDB 计算存储分离的架构实现, PolarDB 在数据库内核性能优化, 最佳实践等等。</div></h3>
              <p>陈宗志 阿里巴巴高级技术专家</p>
            </li>
            <li>
              <i>14:20-15:00</i>
              <h3>让MySQL 8.0给业务增效护航<div class="tips">MySQL 8.0从发2018年GA(8.0.11)到现在接近2年多(8.0.22)共发布了11个版本，目前在Worklog中关于MySQL8.0的Feature超过700个，大量的特性，也让MySQL8.0成为了一个新的数据库，例如在MySQL8.0中高可用解决方案，数据一致性，join优化都得到了进一步的提升，希望通过本次分享中给大家讲解一下MySQL8.0可以给开发人员带来的便利性及一些有用的新特性及使用MySQL8.0新特性的注意事项，让你快速在生产中使用MySQL8.0。希望给你带去帮助。</div></h3>
              <p>吴炳锡 知数堂联合创始人</p>
            </li>
            <li>
              <i>15:00-15:40</i>
              <h3>面向云的分布式数据库设计思路<div class="tips">云计算从 AWS 初创时的牛刀小试到如今巨大的行业和生态，显现出不可逆转的趋势。未来一切都会在云上，云上生态也需要新的基础软件。本次演讲将为你提供：系统化分析云改变了什么？云基础设施和软件融合有哪些方向？</div></h3>
              <p>黄东旭 PingCAP 联合创始人 CTO</p>
            </li>
            <li>
              <i>15:40-15:50</i>
              <h3>现场休息</h3>
            </li>
            <li>
              <i>15:50-16:00</i>
              <h3>抽奖互动</h3>
            </li>
            <li>
              <i>16:00-16:40</i>
              <h3>智能运维AIOPS关键技术速览<div class="tips">从智能运维的平台架构着眼，将抽象出的技术层的关键技术进行速览：数据采集层、数据汇聚层、数据存储层、建模应用层、分析学习层、应用反馈层。</div></h3>
              <p>高驰涛 云智慧技术副总裁</p>
            </li>
            <li>
              <i>16:40-17:20</i>
              <h3>PHP项目容器化线上实践<div class="tips">从传统LNMP应用向容器化改造的实战经验，用软件工程的方法论解决生产系统稳定性问题，自动化一切耗费团队时间的事情，着眼于项目自动化CI/CD、统一监控/告警、服务标准化、集群容灾、提效率降成本、问题排查、服务治理。</div></h3>
              <p>陈朝飞 好未来后端资深专家</p>
            </li>
            <li>
              <i>17:20-18:00</i>
              <h3>一个PHP工程师的进阶之路<div class="tips">结合自己以往的工作经历，分享自己在不同阶段业务开发中找准定位、设定目标、突破瓶颈的思路，如何从一个只会PHP的技术小白到常见语言灵活切换的合格工程师，以及对于职业发展的个人见解。</div></h3>
              <p>秦朋 《PHP 7内核剖析》作者</p>
            </li>
            <li>
              <i>18:00-18:20</i>
              <h3>主持人致辞</h3>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data () {
    return {}
  },
  mounted () {},
  methods: {}
}
</script>
<style lang='scss' scoped>
/* 大会议程 */
.content-process {
  width: 1198px;
  margin: 0 auto;
  color: #ffffff;
  .process-tit {
    font-size: 42px;
    line-height: 42px;
    padding: 58px 0;
    span {
      font-size: 24px;
      line-height: 24px;
      padding-left: 21px;
    }
  }
  .process-content {
    background: #0D3EAB;
    box-shadow: 5px 9px 38px 0px rgba(15, 5, 75, 0.06);
    border-radius: 16px;
    padding: 52px 0;
    position: relative;
    .content_left {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 450px;
      transform: translate(-500px,200px);
      img {
        width: 100%;
      }
    }
    .content-list {
      width: 50%;
      float: left;
      box-sizing: border-box;
      padding-bottom: 20px;
      .list-tit {
        font-size: 30px;
        line-height: 30px;
        color: #FF9A1A;
        padding: 18px 0;
        text-align: center;
        margin-bottom: 37px;
      }
      .list-down {
        position: relative;
        &:before {
          content: '';
          display: block;
          width: 1px;
          border-right: 1px dashed #ffffff;
          height: 100%;
          position: absolute;
          left: 196px;
          top: 0;
        }
        ul {
          padding-left: 16px;
        }
        li {
          padding: 22.5px 0;
          font-size: 20px;
          margin-left: 175px;
          position: relative;
          padding-left: 40px;
          position: relative;
          padding-right: 50px;
          cursor: default;
          &::before {
            content: '';
            left: 1px;
            top: 28px;
            position: absolute;
            width: 10px;
            height: 10px;
            background: #ffffff;
            border-radius: 50%;
          }
          h3 {
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            position: relative;
            .tips {
              display: none;
              position: absolute;
              width: 272px;
              left: 0;
              top: 35px;
              padding: 16px;
              background: #002B8C;
              border-radius: 8px;
              font-size: 14px;
              line-height: 21px;
              z-index: 5;
              text-align: justify;
              &::before {
                content: '';
                display: block;
                position: absolute;
                top: -18px;
                left: 20px;
                width: 0px;
                height: 0px;
                border-width: 10px;
                border-style: solid;
                border-color: transparent transparent #002B8C transparent;
              }
            }
            &:hover {
              .tips {
                display: block;
              }
            }
          }
          p {
            font-size: 16px;
            line-height: 16px;
            padding-top: 12px;
            opacity: 0.7;
          }
          i {
            position: absolute;
            left: -124px;
            top: 22.5px;
            line-height: 20px;
            font-size: 18px;
            opacity: 0.5;
            font-family: Arial;
          }
        }
      }
    }
    .right-list {
      border-left: 2px solid #02339F;
    }
  }
}
@media screen and (max-width: 988px) {
  .content-process {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    .process-tit {
      font-size: 22px;
      line-height: 22px;
      padding: 18px 0;
      span {
        font-size: 14px;
        line-height: 14px;
        padding-left: 11px;
      }
    }
    .process-content {
      padding: 22px 0;
      .content_left {
        display: none;
      }
      .content-list {
        width: 100%;
        padding-bottom: 0;
        .list-tit {
          font-size: 22px;
          line-height: 22px;
          padding: 8px 0;
          margin-bottom: 17px;
          display: none;
        }
        .list-down {
          position: relative;
          &:before {
            left: 30.3%;
          }
          ul {
            padding-left: 0px;
          }
          li {
            padding: 14px 0;
            font-size: 16px;
            margin-left: 29%;
            position: relative;
            padding-left: 20px;
            position: relative;
            padding-right: 10px;
            &::before {
              left: 1px;
              top: 16px;
              width: 8px;
              height: 8px;
            }
            h3 {
              font-size: 14px;
              line-height: 14px;
              .tips {
                display: none;
                width: 172px;
                left: 0;
                top: 35px;
                padding: 10px;
                border-radius: 4px;
                font-size: 12px;
                line-height: 18px;
                z-index: 5;
                text-align: justify;
                &::before {
                  content: '';
                  display: block;
                  position: absolute;
                  top: -18px;
                  left: 20px;
                  width: 0px;
                  height: 0px;
                  border-width: 10px;
                  border-style: solid;
                  border-color: transparent transparent #002B8C transparent;
                }
              }
              &:hover {
                .tips {
                  display: block;
                }
              }
            }
            p {
              font-size: 12px;
              line-height: 12px;
            }
            i {
              position: absolute;
              left: -75px;
              top: 10px;
              font-size: 12px;
            }
          }
        }
      }
      .left-list {
        padding-bottom: 0;
      }
      .right-list {
        margin-top: 0;
        border-left: none;
      }
    }
  }
}
</style>
